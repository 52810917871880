import React from 'react'
import './index.scss'
import SingleCardLayout from '../layouts/singleCardLayout'
// @ts-ignore
import bgImg from '../assets/images/index-bg.jpg'

const IndexPage = () => {
  return (
    <SingleCardLayout title={`Code & Kram 💻`} headerImageUrl={bgImg}>
      <h1 className={'title'}>Hi! 👋</h1>
      <p>
        Ich bin 27 Jahre alt, mein Name ist Dean Eckert, ich bin Softwareentwickler 💻 und Partner der Red Oak
        Consulting GmbH & Co. KG. Hier präsentiere ich mich, was ich mache, und was ich von mir sonst so publizieren
        möchte.
      </p>
      <p>
        Auf meiner letzten Seite stand hier ein genialer Absatz darüber, wie ich einen Opt-In selbst gebaut habe, um
        möglichst gesetzeskonform Cookies einzusetzen. Aber ich sag's wie's ist:
        <ul>
          <li>Scheiß auf Google Analytics</li>
          <li>Scheiß auf Wakatime</li>
        </ul>
        Ich hab keine Lust, mich mit Schrott zu beschäftigen, der mich nervt, der Dich nervt und der niemandem etwas
        bringt. Deshalb gibt's hier erstmal keine Kekse mehr. Vielleicht überleg ich mir das ja nochmal, wenn es einen
        Mehrwert gibt. Bis dahin gelten an der Stelle insbesondere Artikel 1 und 2 des rheinischen Grundgesetztes:
        <ol>
          <li>Et es wie et es.</li>
          <li>Et kütt wie et kütt.</li>
        </ol>
      </p>
      <p>
        Ich entwickle diese Website in meiner Freizeit und werde sie im Laufe der Zeit immer weiterentwickeln (diesmal
        hoffentlich wirklich). Hier werde ich Stück für Stück Inhalte erstellen, die meine Person, Hobbies, Arbeit und
        was mir noch so einfällt betreffen. Meine ersten Schritte, diese Website in irgendeiner Form mit halbwegs
        sinnvollen Inhalten zu füllen ist, einen kleinen Blog zu basteln, auf dem ich halbwegs sinnvolles Zeug schreibe.
      </p>
      <p>
        Technisch basiert diese Website aktuell auf React, Gatsby.js, TypeScript und SASS. Ob das wirklich Sinn macht
        oder ob ich nicht doch lieber eine andere Technologie einsetze, wird sich zeigen. Vorerst
        bleibt's aber einfach dabei. React ist ja eigentlich auch ganz fein, nicht wahr? (Bis die nächste Iteration ein
        txt-Dokument ist 🤡)
      </p>
      <p>Dean Eckert</p>
    </SingleCardLayout>
  )
}

export default IndexPage
